import { default as _5_45month_45cart_45offer0lLreY5StzMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/5-month-cart-offer.vue?macro=true";
import { default as _6_45month_45half_45priceKQvZQzxmXwMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/6-month-half-price.vue?macro=true";
import { default as aboutoxmpTtp0X3Meta } from "/codebuild/output/src710013413/src/zeronet-web/pages/about.vue?macro=true";
import { default as billingv4luMvCyicMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/account/billing.vue?macro=true";
import { default as indexyc8agiYe6sMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/account/index.vue?macro=true";
import { default as profileSJfbyh7b1vMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/account/profile.vue?macro=true";
import { default as account0P0GSRAmNfMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/account.vue?macro=true";
import { default as authorizeh1dl2oNPESMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/authorize.vue?macro=true";
import { default as bbc_45promoYYEAe0S8lfMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/bbc-promo.vue?macro=true";
import { default as chatJQZ5nVo0mdMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/chat.vue?macro=true";
import { default as compass_45embedE4LsB5dmj8Meta } from "/codebuild/output/src710013413/src/zeronet-web/pages/compass-embed.vue?macro=true";
import { default as compassLXB5P0CJkhMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/compass.vue?macro=true";
import { default as contactwvx28gqSOwMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/contact.vue?macro=true";
import { default as hyperfibrezgAvZQJ1BqMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/hyperfibre.vue?macro=true";
import { default as index91bC5AOUCeMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/index.vue?macro=true";
import { default as internet_45speed_45testynO1RQMuSmMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/internet-speed-test.vue?macro=true";
import { default as loginIwRCjsvPSPMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/login.vue?macro=true";
import { default as logoutnfoohHZMLNMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/logout.vue?macro=true";
import { default as our_45planscTKBNRbdldMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/our-plans.vue?macro=true";
import { default as plansxwfF3YO59lMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/plans.vue?macro=true";
import { default as privacy8DlbY4WdSfMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/privacy.vue?macro=true";
import { default as promotionsIDB2lZR81bMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/promotions.vue?macro=true";
import { default as _1dwg4aV7xoCMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/register/1.vue?macro=true";
import { default as _2rRQ04l7DcrMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/register/2.vue?macro=true";
import { default as _3Y9ZdzyrhfwMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/register/3.vue?macro=true";
import { default as _4W4Z06t9hCwMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/register/4.vue?macro=true";
import { default as _5QC7HBeFaXBMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/register/5.vue?macro=true";
import { default as indexuvSwGph4eKMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/register/index.vue?macro=true";
import { default as successX5j2NpUhNoMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/register/success.vue?macro=true";
import { default as registerZIbnRzP5kQMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/register.vue?macro=true";
import { default as simply_45broadbandLS8i3hWP7zMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/simply-broadband.vue?macro=true";
import { default as studentcard_45exclusive_45offer7NYHDnNtvFMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/studentcard-exclusive-offer.vue?macro=true";
import { default as sustainabilityV76yPpPhHVMeta } from "/codebuild/output/src710013413/src/zeronet-web/pages/sustainability.vue?macro=true";
import { default as termsANddIH0EO6Meta } from "/codebuild/output/src710013413/src/zeronet-web/pages/terms.vue?macro=true";
export default [
  {
    name: _5_45month_45cart_45offer0lLreY5StzMeta?.name ?? "5-month-cart-offer",
    path: _5_45month_45cart_45offer0lLreY5StzMeta?.path ?? "/5-month-cart-offer",
    meta: _5_45month_45cart_45offer0lLreY5StzMeta || {},
    alias: _5_45month_45cart_45offer0lLreY5StzMeta?.alias || [],
    redirect: _5_45month_45cart_45offer0lLreY5StzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/5-month-cart-offer.vue").then(m => m.default || m)
  },
  {
    name: _6_45month_45half_45priceKQvZQzxmXwMeta?.name ?? "6-month-half-price",
    path: _6_45month_45half_45priceKQvZQzxmXwMeta?.path ?? "/6-month-half-price",
    meta: _6_45month_45half_45priceKQvZQzxmXwMeta || {},
    alias: _6_45month_45half_45priceKQvZQzxmXwMeta?.alias || [],
    redirect: _6_45month_45half_45priceKQvZQzxmXwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/6-month-half-price.vue").then(m => m.default || m)
  },
  {
    name: aboutoxmpTtp0X3Meta?.name ?? "about",
    path: aboutoxmpTtp0X3Meta?.path ?? "/about",
    meta: aboutoxmpTtp0X3Meta || {},
    alias: aboutoxmpTtp0X3Meta?.alias || [],
    redirect: aboutoxmpTtp0X3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/about.vue").then(m => m.default || m)
  },
  {
    path: account0P0GSRAmNfMeta?.path ?? "/account",
    children: [
  {
    name: billingv4luMvCyicMeta?.name ?? "account-billing",
    path: billingv4luMvCyicMeta?.path ?? "billing",
    meta: billingv4luMvCyicMeta || {},
    alias: billingv4luMvCyicMeta?.alias || [],
    redirect: billingv4luMvCyicMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: indexyc8agiYe6sMeta?.name ?? "account",
    path: indexyc8agiYe6sMeta?.path ?? "",
    meta: indexyc8agiYe6sMeta || {},
    alias: indexyc8agiYe6sMeta?.alias || [],
    redirect: indexyc8agiYe6sMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: profileSJfbyh7b1vMeta?.name ?? "account-profile",
    path: profileSJfbyh7b1vMeta?.path ?? "profile",
    meta: profileSJfbyh7b1vMeta || {},
    alias: profileSJfbyh7b1vMeta?.alias || [],
    redirect: profileSJfbyh7b1vMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/account/profile.vue").then(m => m.default || m)
  }
],
    name: account0P0GSRAmNfMeta?.name ?? undefined,
    meta: account0P0GSRAmNfMeta || {},
    alias: account0P0GSRAmNfMeta?.alias || [],
    redirect: account0P0GSRAmNfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/account.vue").then(m => m.default || m)
  },
  {
    name: authorizeh1dl2oNPESMeta?.name ?? "authorize",
    path: authorizeh1dl2oNPESMeta?.path ?? "/authorize",
    meta: authorizeh1dl2oNPESMeta || {},
    alias: authorizeh1dl2oNPESMeta?.alias || [],
    redirect: authorizeh1dl2oNPESMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/authorize.vue").then(m => m.default || m)
  },
  {
    name: bbc_45promoYYEAe0S8lfMeta?.name ?? "bbc-promo",
    path: bbc_45promoYYEAe0S8lfMeta?.path ?? "/bbc-promo",
    meta: bbc_45promoYYEAe0S8lfMeta || {},
    alias: bbc_45promoYYEAe0S8lfMeta?.alias || [],
    redirect: bbc_45promoYYEAe0S8lfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/bbc-promo.vue").then(m => m.default || m)
  },
  {
    name: chatJQZ5nVo0mdMeta?.name ?? "chat",
    path: chatJQZ5nVo0mdMeta?.path ?? "/chat",
    meta: chatJQZ5nVo0mdMeta || {},
    alias: chatJQZ5nVo0mdMeta?.alias || [],
    redirect: chatJQZ5nVo0mdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: compass_45embedE4LsB5dmj8Meta?.name ?? "compass-embed",
    path: compass_45embedE4LsB5dmj8Meta?.path ?? "/compass-embed",
    meta: compass_45embedE4LsB5dmj8Meta || {},
    alias: compass_45embedE4LsB5dmj8Meta?.alias || [],
    redirect: compass_45embedE4LsB5dmj8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/compass-embed.vue").then(m => m.default || m)
  },
  {
    name: compassLXB5P0CJkhMeta?.name ?? "compass",
    path: compassLXB5P0CJkhMeta?.path ?? "/compass",
    meta: compassLXB5P0CJkhMeta || {},
    alias: compassLXB5P0CJkhMeta?.alias || [],
    redirect: compassLXB5P0CJkhMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/compass.vue").then(m => m.default || m)
  },
  {
    name: contactwvx28gqSOwMeta?.name ?? "contact",
    path: contactwvx28gqSOwMeta?.path ?? "/contact",
    meta: contactwvx28gqSOwMeta || {},
    alias: contactwvx28gqSOwMeta?.alias || [],
    redirect: contactwvx28gqSOwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: hyperfibrezgAvZQJ1BqMeta?.name ?? "hyperfibre",
    path: hyperfibrezgAvZQJ1BqMeta?.path ?? "/hyperfibre",
    meta: hyperfibrezgAvZQJ1BqMeta || {},
    alias: hyperfibrezgAvZQJ1BqMeta?.alias || [],
    redirect: hyperfibrezgAvZQJ1BqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/hyperfibre.vue").then(m => m.default || m)
  },
  {
    name: index91bC5AOUCeMeta?.name ?? "index",
    path: index91bC5AOUCeMeta?.path ?? "/",
    meta: index91bC5AOUCeMeta || {},
    alias: index91bC5AOUCeMeta?.alias || [],
    redirect: index91bC5AOUCeMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: internet_45speed_45testynO1RQMuSmMeta?.name ?? "internet-speed-test",
    path: internet_45speed_45testynO1RQMuSmMeta?.path ?? "/internet-speed-test",
    meta: internet_45speed_45testynO1RQMuSmMeta || {},
    alias: internet_45speed_45testynO1RQMuSmMeta?.alias || [],
    redirect: internet_45speed_45testynO1RQMuSmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/internet-speed-test.vue").then(m => m.default || m)
  },
  {
    name: loginIwRCjsvPSPMeta?.name ?? "login",
    path: loginIwRCjsvPSPMeta?.path ?? "/login",
    meta: loginIwRCjsvPSPMeta || {},
    alias: loginIwRCjsvPSPMeta?.alias || [],
    redirect: loginIwRCjsvPSPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutnfoohHZMLNMeta?.name ?? "logout",
    path: logoutnfoohHZMLNMeta?.path ?? "/logout",
    meta: logoutnfoohHZMLNMeta || {},
    alias: logoutnfoohHZMLNMeta?.alias || [],
    redirect: logoutnfoohHZMLNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: our_45planscTKBNRbdldMeta?.name ?? "our-plans",
    path: our_45planscTKBNRbdldMeta?.path ?? "/our-plans",
    meta: our_45planscTKBNRbdldMeta || {},
    alias: our_45planscTKBNRbdldMeta?.alias || [],
    redirect: our_45planscTKBNRbdldMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/our-plans.vue").then(m => m.default || m)
  },
  {
    name: plansxwfF3YO59lMeta?.name ?? "plans",
    path: plansxwfF3YO59lMeta?.path ?? "/plans",
    meta: plansxwfF3YO59lMeta || {},
    alias: plansxwfF3YO59lMeta?.alias || [],
    redirect: plansxwfF3YO59lMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: privacy8DlbY4WdSfMeta?.name ?? "privacy",
    path: privacy8DlbY4WdSfMeta?.path ?? "/privacy",
    meta: privacy8DlbY4WdSfMeta || {},
    alias: privacy8DlbY4WdSfMeta?.alias || [],
    redirect: privacy8DlbY4WdSfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: promotionsIDB2lZR81bMeta?.name ?? "promotions",
    path: promotionsIDB2lZR81bMeta?.path ?? "/promotions",
    meta: promotionsIDB2lZR81bMeta || {},
    alias: promotionsIDB2lZR81bMeta?.alias || [],
    redirect: promotionsIDB2lZR81bMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/promotions.vue").then(m => m.default || m)
  },
  {
    path: registerZIbnRzP5kQMeta?.path ?? "/register",
    children: [
  {
    name: _1dwg4aV7xoCMeta?.name ?? "register-1",
    path: _1dwg4aV7xoCMeta?.path ?? "1",
    meta: _1dwg4aV7xoCMeta || {},
    alias: _1dwg4aV7xoCMeta?.alias || [],
    redirect: _1dwg4aV7xoCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/register/1.vue").then(m => m.default || m)
  },
  {
    name: _2rRQ04l7DcrMeta?.name ?? "register-2",
    path: _2rRQ04l7DcrMeta?.path ?? "2",
    meta: _2rRQ04l7DcrMeta || {},
    alias: _2rRQ04l7DcrMeta?.alias || [],
    redirect: _2rRQ04l7DcrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/register/2.vue").then(m => m.default || m)
  },
  {
    name: _3Y9ZdzyrhfwMeta?.name ?? "register-3",
    path: _3Y9ZdzyrhfwMeta?.path ?? "3",
    meta: _3Y9ZdzyrhfwMeta || {},
    alias: _3Y9ZdzyrhfwMeta?.alias || [],
    redirect: _3Y9ZdzyrhfwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/register/3.vue").then(m => m.default || m)
  },
  {
    name: _4W4Z06t9hCwMeta?.name ?? "register-4",
    path: _4W4Z06t9hCwMeta?.path ?? "4",
    meta: _4W4Z06t9hCwMeta || {},
    alias: _4W4Z06t9hCwMeta?.alias || [],
    redirect: _4W4Z06t9hCwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/register/4.vue").then(m => m.default || m)
  },
  {
    name: _5QC7HBeFaXBMeta?.name ?? "register-5",
    path: _5QC7HBeFaXBMeta?.path ?? "5",
    meta: _5QC7HBeFaXBMeta || {},
    alias: _5QC7HBeFaXBMeta?.alias || [],
    redirect: _5QC7HBeFaXBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/register/5.vue").then(m => m.default || m)
  },
  {
    name: indexuvSwGph4eKMeta?.name ?? "register",
    path: indexuvSwGph4eKMeta?.path ?? "",
    meta: indexuvSwGph4eKMeta || {},
    alias: indexuvSwGph4eKMeta?.alias || [],
    redirect: indexuvSwGph4eKMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: successX5j2NpUhNoMeta?.name ?? "register-success",
    path: successX5j2NpUhNoMeta?.path ?? "success",
    meta: successX5j2NpUhNoMeta || {},
    alias: successX5j2NpUhNoMeta?.alias || [],
    redirect: successX5j2NpUhNoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/register/success.vue").then(m => m.default || m)
  }
],
    name: registerZIbnRzP5kQMeta?.name ?? undefined,
    meta: registerZIbnRzP5kQMeta || {},
    alias: registerZIbnRzP5kQMeta?.alias || [],
    redirect: registerZIbnRzP5kQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/register.vue").then(m => m.default || m)
  },
  {
    name: simply_45broadbandLS8i3hWP7zMeta?.name ?? "simply-broadband",
    path: simply_45broadbandLS8i3hWP7zMeta?.path ?? "/simply-broadband",
    meta: simply_45broadbandLS8i3hWP7zMeta || {},
    alias: simply_45broadbandLS8i3hWP7zMeta?.alias || [],
    redirect: simply_45broadbandLS8i3hWP7zMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/simply-broadband.vue").then(m => m.default || m)
  },
  {
    name: studentcard_45exclusive_45offer7NYHDnNtvFMeta?.name ?? "studentcard-exclusive-offer",
    path: studentcard_45exclusive_45offer7NYHDnNtvFMeta?.path ?? "/studentcard-exclusive-offer",
    meta: studentcard_45exclusive_45offer7NYHDnNtvFMeta || {},
    alias: studentcard_45exclusive_45offer7NYHDnNtvFMeta?.alias || [],
    redirect: studentcard_45exclusive_45offer7NYHDnNtvFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/studentcard-exclusive-offer.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityV76yPpPhHVMeta?.name ?? "sustainability",
    path: sustainabilityV76yPpPhHVMeta?.path ?? "/sustainability",
    meta: sustainabilityV76yPpPhHVMeta || {},
    alias: sustainabilityV76yPpPhHVMeta?.alias || [],
    redirect: sustainabilityV76yPpPhHVMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: termsANddIH0EO6Meta?.name ?? "terms",
    path: termsANddIH0EO6Meta?.path ?? "/terms",
    meta: termsANddIH0EO6Meta || {},
    alias: termsANddIH0EO6Meta?.alias || [],
    redirect: termsANddIH0EO6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src710013413/src/zeronet-web/pages/terms.vue").then(m => m.default || m)
  }
]